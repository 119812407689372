<script>
export default {
  props: {
    statistics: Array,
    match_id: String,
  },
  data() {
    return {};
  },
  methods:{
    calculatePercentage(value, totalOther) {
      const total = value + totalOther;
      return total ? ((value / total) * 100).toFixed(2) : 0;
    },
  }
};
</script>
<template>
  <div class="container mt-3">
    <div class="row">
      <div
        v-for="(stat, index) in statistics"
        :key="index"
        class="col-md-6 mb-3"
      >
        <h6>{{ stat.stat_name }}</h6>
        <div class="d-flex justify-content-between mb-2">
          <p>{{ stat.home_points }}</p>
          <p>{{ stat.away_points }}</p>
        </div>
        <div class="progress mb-2">
          <div
            class="progress-bar bg-primary"
            :style="{
              width: calculatePercentage(stat.home_points, stat.away_points) + '%',
            }"
            role="progressbar"
            :aria-valuenow="calculatePercentage(stat.home_points, stat.away_points)"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            class="progress-bar bg-success"
            :style="{
              width: calculatePercentage(stat.away_points, stat.home_points) + '%',
            }"
            role="progressbar"
            :aria-valuenow="calculatePercentage(stat.away_points, stat.home_points)"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div class="d-flex justify-content-between">
          <span>Home Team</span>
          <span>Away Team</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
